.formatedText {
    position: relative;
    width: 100%;
    max-width: 700px;
    margin-top: 60px;
    margin-bottom: 60px;
    z-index: 6;
}

.formatedText h2,
.formatedText h3,
.formatedText h4,
.formatedText h5 {
    color: #10264d;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: 700;
}
.formatedText h2 {
    font-size: 2.25rem;
}

.formatedText p {
    line-height: 1.5em;
    margin-bottom: 1.2rem;
}
