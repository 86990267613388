.grid {
    position: relative;
    padding-top: 70px;
}

.formBg {
    background-color: #10264d;
    margin-top: 0px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.box {
    position: relative;
    background: white;
    padding: 30px;
    border-radius: 8px;
    z-index: 3;
    width: 100%;
    z-index: 10;
}

.container {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

.stickyWrapper {
    position: relative;
    background-color: #10264d;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 992px) {
    .grid {
        padding-top: 115px;
    }
    .formBg {
        margin-top: -523px;
        padding-top: 115px;
        padding-bottom: 60px;
    }
    .stickyWrapper {
        position: sticky;
        top: 115px;
        background-color: transparent;
        padding-top: 0;
    }
    .stickyContent {
        width: 320px;
        margin-left: auto;
    }
    .container {
        padding-right: 360px;
    }
}

@media (min-width: 1200px) {
    .formBg {
        margin-top: -523px;
        padding-top: 165px;
    }
    .stickyWrapper {
        top: 165px;
    }
    .container {
        padding-right: 400px;
    }
    
}