.textFeatures {
    background-color: #f3f6f8;
    padding: 30px 15px;
}

/* HEADING */
.textFeatures h2 {
    color: #10264d;
    font-size: 35px;
    line-height: 1.3;
    font-weight: 700;
    text-align: center;
}

.textFeatures h2 br {
    display: none;
}

@media (min-width: 600px) {
    .textFeatures h2 {
        font-size: 36px;
    }
    .textFeatures h2 br {
        display: inline;
    }
}

@media (min-width: 950px) {
    .textFeatures {
        padding: 60px 0;
    }
    .textFeatures h2 {
        font-size: 47px;
    }
}

@media (min-width: 1200px) {
    .textFeatures {
        padding: 100px 0;
    }
}

/* SUBHEADING */
.textFeatures h2 + p {
    color: #000;
    font-size: 20px;
    line-height: 1.5;
    text-align: center;
    font-weight: 400;
    margin-top: 10px;
    display: block;
    margin-bottom: 30px;
}

@media (min-width: 950px) {
    .textFeatures h2 + p {
        font-size: 22px;
        margin-bottom: 60px;
    }
}
@media (min-width: 1200px) {
    .textFeatures h2 + p {
        margin-bottom: 80px;
    }
}

/* FEATURES */
.textFeatures div div img {
    max-height: 150px;
    margin-bottom: 30px;
}
.textFeatures div div p {
    margin-bottom: 50px;
    text-align: left;
    font-size: 15px;
    line-height: 1.5;
    color: #414141;
}

@media (min-width: 950px) {
    .textFeatures div div p {
        padding: 0 20px;
        margin-bottom: 0;
    }
}

@media (min-width: 1200px) {
    .textFeatures div div img {
        margin-bottom: 50px;
    }
}