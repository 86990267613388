.subscribeForm {
    position: relative;
}

/**
 * Content
 */
.content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    border-radius: 8px;
    padding: 30px;
    background: #e7f4fe;
    z-index: 5;
}
.content h1 {
    color: #10264d;
    font-size: 32px;
    line-height: 1.25em;
    font-weight: 700;
}
.content p {
    color: #566683;
    font-size: 16px;
    line-height: 1.25em;
}

/**
 * Form
 */
.label {
    color: #343c4e;
    margin-bottom: 5px;
    font-size: 14px;
}
.input {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    height: 50px;
    border: 0px;
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 15px 25px;
    text-align: left;
    font-size: 15px;
}
.input:focus,
.input:active {
    outline: none;
    box-shadow: none;
}
.input::placeholder,
.input:-ms-input-placeholder,
.input::-ms-input-placeholder {
    color: #a3aab1;
}
.button {
    position: relative;
    width: 100%;
    background-color: #1494fc;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    padding: 12px 30px;
}
button.has-subtitle:after {
    content: attr(data-subtitle);
    position: absolute;
    display: inline-block;
    width: 100%;
    top: 100%;
    left: 0;
    margin-top: 10px;
    font-size: 8px;
    text-transform: uppercase;
    color: #8893a6;
}

/**
 * Icons
 */
.icon1,
.icon2,
.icon3,
.icon4,
.icon5 {
    display: none;
    position: absolute;
}

@media (min-width: 1200px) {
    .icon1,
    .icon2,
    .icon3,
    .icon4,
    .icon5 {
        display: inline-block;
    }
}

.icon1 {
    top: -30px;
    right: -30px;
    width: 80px;
    height: 80px;
    z-index: 6;
}
.icon2 {
    top: calc(50% - 50px);
    left: -70px;
    width: 92px;
    height: 85.7px;
    z-index: 6;
}
.icon3 {
    bottom: 30px;
    right: -35px;
    width: 57px;
    height: 57px;
    z-index: 6;
}
.icon4 {
    top: -45px;
    left: -25px;
    width: 80px;
    height: 68px;
    z-index: 4;
}
.icon5 {
    bottom: -30px;
    left: -35px;
    width: 45px;
    height: 45px;
    z-index: 4;
}
